<template>
	<div class="breadcrumbs">
		<v-breadcrumbs :items="crumbs">
			<template v-slot:item="props">
				<v-breadcrumbs-item :to="props.item.to" :class="props.item.disabled ? 'disabled' : null" exact>
					{{
						props.item.text.toLowerCase()
					}}
				</v-breadcrumbs-item>
			</template>
			<template v-slot:divider>
				<v-icon class="fa-rotate-90">
					$vuetify.icons.chevron
				</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
export default {
	name: 'Breadcrumbs',
	props: {
		crumbs: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss">
.breadcrumbs {
	a {
		#{$interact} {
			color: var(--v-primary-base);
		}
	}
	@media only screen and (max-width: #{map-get($grid-breakpoints, 'sm') - 1}) {
		li:nth-child(2n) {
			padding: 0 gutter-size(2);
		}
	}
	@media only screen and (max-width: 320px) {
		li:nth-child(2n) {
			padding: 0 gutter-size(1);
		}
	}
}
</style>

<style scoped lang="scss">
.breadcrumbs {
	ul {
		padding: gutter-size(4) 0;
	}
	li {
		font-size: $font-size-xs;
	}
	.disabled {
		color: $grey-mid;
		pointer-events: none;
	}
	@media only screen and (max-width: 320px) {
		li {
			font-size: 12px;
		}
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) {
		li {
			&:nth-child(2n) {
				padding: 0 gutter-size(3);
			}
		}
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, 'xl')}) {
		li {
			font-size: $font-size-sm;
		}
	}
}
</style>
